.logo-vertical {
  padding-top: 30px;
  padding-bottom: 30px;
}

.logo-vertical img {
  width: 190px;
  margin: 0 auto;
}

.logo-vertical span {
  font-size: 60px;
  line-height: 1;
  margin-top: -10px;
}

.logo-horizontal img {
  width: 56px;
  margin: 0 auto;
}

.logo-horizontal span {
  font-size: 25px;
  line-height: 1;
  margin-top: -10px;
}

.logo-centered-horizontal {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

/* don't decorate the logo if inside a link */
a .logo-horizontal, a .logo-vertical, a:hover .logo-horizontal, a:hover .logo-vertical {
  text-decoration: none;
  color: #212529;
}
