.react-datepicker__input-container input {
  border: 1px solid #ced4da;
  padding: 6px 10px;
}


.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--selected:hover {
  background-color: #2e1b59;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #2e1b59;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected {
  background-color: #63508b;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #63508b;
}
