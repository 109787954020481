.badge-group > * {
  display: inline-flex;
  margin-right: 0.25rem;
}

.badge-group > *:last-child {
  margin-right: 0px;
}

.badge-column, .badge-numerical, .badge-textual {
  background-color: #f0f0f0;
  font-size: .7rem;
}

.badge-numerical svg {
  color: #1ab082;
}

.badge-textual svg {
  color: #4d96b2;
}

.badge-categorical {
  color: #fff;
  background-color: #4d96b2;
}

.badge-number {
  color: #fff;
  background-color: #1ab082;
}

.badge-corner-button{
  width: 12px;
  height: 12px;
  margin-top: -18px;
  margin-left: 1px;
  padding: 0px;
  margin-right: -6px;
}

.badge-corner-button svg{
  color:#fdfdfe;
  fill: #757575;
  stroke-width: 2;
}

