.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 4;
  max-height: 16vh;
  overflow-y: auto;
  margin-left: -4px;
}

.dropdown-content div {
  color: black;
  padding: 4px 4px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: inline-block;
}

.badge-button{
  width: 12px;
  height: 12px;
  margin-top: -18px;
  margin-left: 1px;
  padding: 0px;
}

.dropdown-content div:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}
