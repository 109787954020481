/*
 * Icon sizes
 */

.feather {
  width: 15px;
  height: 17px;
  vertical-align: text-bottom;
}

.feather-xs {
  width: 13px;
  height: 13px;
  vertical-align: text-bottom;
}

.feather-xs-w {
  width: 17px;
  height: 13px;
  vertical-align: text-bottom;
}

.feather-lg {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}

.btn .feather {
  width: 18px;
  height: 20px;
}

.btn-sm .feather {
  width: 16px;
  height: 21px;
}

/* to be applied to buttons that should look like a simple link */
.btn-link {
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0px;
}

html, body, body #root, .container-vh-full {
  overflow: hidden;
  height: 100%;
}

.container-vh-scroll {
  overflow: auto;
  height: 100%;
}
